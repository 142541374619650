<template>
  <div>
    <!-- <div class="m-b-5">
      <Alert
        type="error"
        show-icon
        closable
      >
        操作提示
        <Icon
          type="ios-alert-outline"
          size="32"
          slot="icon"
        />
        <template slot="desc">
          存在制作状态的任务无法进行批量操作，请单独操作。
        </template>
</Alert>
</div> -->
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select v-model="query.status" size="small" :clearable="true" placeholder="选择状态">
          <Option v-for="item in statusArray" :key="item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.taskitemType" size="small" :clearable="true" placeholder="选择任务类型">
          <Option v-for="item in taskTypeArray" :key="item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.taskitemStatus" size="small" :clearable="true" placeholder="选择工单状态">
          <Option v-for="item in taskItemStatusArray" :key="item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="6">
        <Input size="small" placeholder="关键词：发起人或资源编号" v-model="query.keyword" />
      </i-col>
      <i-col span="6">
        <Button type="primary" icon="ios-search" class="m-r-5" size="small" @click="handleSearch">搜索</Button>
        <Button type="success" size="small" class="m-r-5" :disabled="selectedItems.length === 0"
          @click="handelSubmit(1)">确认撤销</Button>
        <Button type="error" size="small" class="m-r-5" :disabled="selectedItems.length === 0"
          @click="handelSubmit(2)">拒绝撤销</Button>
      </i-col>
    </Row>
    <div>
      <Table stripe size="small" max-height="720" :data="list" :columns="tableColumns"
        @on-selection-change="selectionChange"></Table>
      <div class="paging_style">
        <Page :total="total" size="small" :page-size="query.pageSize" show-total show-elevator show-sizer
          :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' :current="query.pageNumber"
          @on-change="handleChangePage"></Page>
      </div>
    </div>
    <Modal v-model="revokeModal">
      <p slot="header" class="text-center">确认撤销操作</p>
      <p v-if="revokeTask.produceStatus === 3">当前任务已完成制作，只能撤销该任务。</p>
      <!-- <div v-else>
        <p class="m-b-10">当前存在制作任务，是否要取消制作任务？</p>
        <Checkbox v-model="deleteProduce">取消制作任务</Checkbox>
      </div> -->
      <p class="m-t-10 text-orange" v-if="operRole === 1">
        提示：若撤销任务的制作状态或工单状态为“执行中”，确认撤销后，该任务将交由供应商确认，供应商确认撤销，该任务撤销成功，反之，则撤销失败。</p>
      <div slot="footer">
        <Button type="text" @click="revokeModal = false">
          <span>取消</span>
        </Button>
        <Button type="primary" @click="handelSubmit(1)">
          <span>确定</span>
        </Button>
      </div>
    </Modal>
    <PendingTaskItem ref="pendingTaskItem" :taskIds="selectedTaskIds" :taskitemIds="taskitemIds" :cancel="true"
      :onSuccessFun="initData" @confirmCancel="handleConfirmSubmit">
    </PendingTaskItem>
  </div>
</template>

<script lang="js">
// import { getTaskType } from '@/api/msp/status'
import { getRevokePage, revokeTaskitem, getSupplierRevokePage, getRevokeStatus } from '@/api/msp/terminate'
import { getTaskItemStatus, getTaskType } from '@/api/msp/status'
import { getOtherTaskitemInfo } from '@/api/msp/hangup'
import PendingTaskItem from './PendingTaskItem'
// import { formatEnable } from '@/utils/tagStatus'
export default {
  props: {
    // 操作角色，1：运营管理，2：供应商
    operRole: {
      type: Number,
      default: 1
    }
  },
  components: { PendingTaskItem },
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      statusArray: [
        // { value: 0, name: '申请中' },
        // { value: 1, name: '已通过' },
        // { value: 2, name: '已拒绝' },
        // { value: 3, name: '供应商确认中' }
      ],
      query: {
        status: 0,
        taskitemType: undefined,
        taskitemStatus: undefined,
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      revokeModal: false,
      revokeTask: {},
      revokeStatus: 1,
      // deleteProduce: false,
      // taskTypeArray: [],
      selectedItems: [], // 选中任务集合
      selectedTaskIds: [], // 选中项taskId集合
      taskitemIds: [], // 选中任务id集合
      batchOperation: true, // 批量操作
      list: [],
      total: 0,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        {
          title: '任务类型',
          align: 'center',
          width: 120,
          fixed: 'left',
          render: (h, params) => {
            return h('span', params.row.taskitem.typeName)
          }
        },
        { title: '任务名', key: 'name', width: 160, fixed: 'left' },
        {
          title: '所属站台',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitem.domainName)
          }
        },
        {
          title: '位置',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitem.positionName)
          }
        },
        {
          title: '画面数',
          minWidth: 80,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitem.side)
          }
        },
        {
          title: '画面来源',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitem.picturesourceName)
          }
        },
        { title: '制作状态', align: 'center', minWidth: 80, key: 'produceStatusName' },
        { title: '操作人', align: 'center', minWidth: 80, key: 'userName' },
        { title: '撤销状态', align: 'center', minWidth: 80, key: 'statusName' },
        { title: '撤销说明', align: 'center', minWidth: 100, key: 'remark', ellipsis: true, tooltip: true },
        {
          title: '工单状态',
          align: 'center',
          minWidth: 80,
          render: (h, params) => {
            return h('span', params.row.taskitem.statusName)
          }
        }
        // {
        //   title: '操作',
        //   align: 'center',
        //   width: 140,
        //   render: (h, params) => {
        //     // 存在制作状态，则进行单独操作
        //     if (params.row.produceStatus !== -1 && [0, 3].includes(params.row.status)) {
        //       return h('div', [
        //         h('a', {
        //           on: {
        //             click: () => {
        //               this.batchOperation = false
        //               this.revokeTask = params.row
        //               // this.selectedItems = [{ id: params.row.id, produceStatus: params.row.produceStatus }]
        //               this.revokeModal = true
        //             }
        //           }
        //         }, '确认撤销'),
        //         h('a', {
        //           style: { color: '#ef4f4f', marginLeft: '5px' },
        //           on: {
        //             click: () => {
        //               // this.selectedItems = [{ id: params.row.id, produceStatus: params.row.produceStatus }]
        //               this.batchOperation = false
        //               this.revokeTask = params.row
        //               this.handelSubmit(2)
        //             }
        //           }
        //         }, '拒绝撤销')
        //       ])
        //     } else {
        //       return null
        //     }
        //   }
        // }
      ],
      taskTypeArray: [],
      taskItemStatusArray: []
    }
  },
  // computed: {
  //   taskTypeArray () {
  //     const result = this.$store.state.workOrder.taskTypeArray
  //     return result.filter(x => x.value > 0)
  //   }
  // },
  created () {
    // this.getTaskTypeData()
    switch (this.operRole) {
      case 1:
        // 管理端
        this.query.status = 0
        break
      case 2:
        this.query.status = 3
        break
    }
    this.getRevokeStatusData()
    this.getTaskTypeData()
    this.getTaskItemStatusData()
    this.initData()
  },
  methods: {
    initData () {
      switch (this.operRole) {
        case 1:
          this.getTableData()
          break
        case 2:
          this.getSupplierData()
          break
      }
    },
    getRevokeStatusData () {
      getRevokeStatus().then(res => {
        if (res && !res.errcode) {
          this.statusArray = res
          if (this.operRole === 2) { // 供应商移除“申请中”状态
            this.statusArray = this.statusArray.filter(x => x.value !== 0)
          }
        }
      })
    },
    /**
     * 获取任务类型数据
     */
    getTaskTypeData () {
      getTaskType().then(res => {
        if (res && !res.errcode) {
          this.taskTypeArray = res
        } else {
          this.taskTypeArray = []
        }
      })
    },
    /**
     * 获取任务状态类型
     */
    getTaskItemStatusData () {
      getTaskItemStatus().then(res => {
        if (res && !res.errcode) {
          this.taskItemStatusArray = res
        } else {
          this.taskItemStatusArray = []
        }
      })
    },
    getTableData () {
      getRevokePage(this.query).then(res => {
        if (res && !res.errcode) {
          // 撤销状态为非申请中或者制作状态为不执行，启用复选框
          res.list.forEach(item => {
            // item._disabled = item.status !== 0 || item.produceStatus !== -1
            item._disabled = item.status !== 0
          })
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    /**
     * 获取供应商端撤销任务数据
     */
    getSupplierData () {
      getSupplierRevokePage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          // 撤销状态为非申请中或者制作状态为不执行，启用复选框
          res.list.forEach(item => {
            // item._disabled = item.status !== 3 || item.produceStatus !== -1
            item._disabled = item.status !== 3
          })
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    // getTaskTypeData () {
    //   getTaskType().then(res => {
    //     this.taskTypeArray = res
    //   })
    // },
    handleSearch () {
      this.query.pageNumber = 1
      this.selectedItems = []
      this.initData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    // validSelectionsWorkOrderStatus (selections) {
    //   // 获取选中项的工单状态集合
    //   const workOrderStatusArray = selections.map(x => x.workOrderStatus)
    //   // 判断选中项是否为同一种工单状态
    //   return Array.from(new Set(workOrderStatusArray)).length > 1
    // },
    selectionChange (selections) {
      this.selectedItems = selections.map(x => {
        return { id: x.id, produceStatus: x.produceStatus, taskId: x.taskId, taskitemId: x.taskitemId, workOrderStatus: x.taskitem.status }
      })
      // const valid = this.validSelectionsWorkOrderStatus(this.selectedItems)
      // if (valid) {
      //   this.$Notice.warning({ desc: '请选择工单状态相同的任务项进行操作！' })
      //   return false
      // }
    },
    handelSubmit (status) {
      if (status === 1) { // 确定撤销
        // 确定撤销前获取是否存在需要处理的任务
        this.selectedTaskIds = this.selectedItems.map(x => x.taskId)
        this.taskitemIds = this.selectedItems.map(x => x.taskitemId)
        const postData = {
          pageNumber: 1,
          pageSize: 5,
          taskIds: JSON.stringify(this.selectedTaskIds),
          taskitemIds: JSON.stringify(this.taskitemIds)
        }

        getOtherTaskitemInfo(postData).then(res => {
          if (!res || res.totalRow === 0) {
            this.handleConfirmSubmit(status)
          } else {
            this.$Modal.confirm({
              title: '操作提示',
              content: '当前存在需要人工干预的任务项，点击“确定”按钮，显示需要人工干预的任务项详情。',
              onOk: () => {
                // 弹窗处理界面
                this.$nextTick(() => {
                  this.$refs.pendingTaskItem.init()
                })
              }
            })
          }
        })
      } else { // 拒绝撤销
        this.handleConfirmSubmit(status)
      }
    },
    handleConfirmSubmit (status) {
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('div', [
            h('p', { class: ['m-b-10'] }, status === 1 ? '确定要撤销所选任务？' : '确定要拒绝所选任务？'),
            this.operRole === 1 ? h('p', { class: ['text-orange'] }, '提示：若撤销任务的工单状态为“执行中”，确认撤销后，该任务将交由供应商确认，供应商确认撤销，该任务撤销成功；反之，则撤销失败。') : null

          ])
        },
        // content: status === 1 ? '确定要撤销所选任务？' : '确定要拒绝所选任务？',
        onOk: () => {
          revokeTaskitem({ applyIds: JSON.stringify(this.batchOperation ? this.selectedItems.map(x => x.id) : [this.revokeTask.id]), status: status, operRole: this.operRole }).then(res => {
            this.revokeModal = false
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedItems = []
              this.revokeTask = {}
              this.initData()
            }
          })
        }
      })
    }
  }
}
</script>
